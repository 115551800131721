import { render, staticRenderFns } from "./zhizezhifa.vue?vue&type=template&id=220323e5&scoped=true&"
import script from "./zhizezhifa.vue?vue&type=script&lang=ts&"
export * from "./zhizezhifa.vue?vue&type=script&lang=ts&"
import style0 from "./zhizezhifa.vue?vue&type=style&index=0&id=220323e5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220323e5",
  null
  
)

export default component.exports