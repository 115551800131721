

















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetAnalysis, GetAnalysisExport } from "@/request/yian";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private searchData: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private data: any = {
    证型: {
      分组变量: "不同医家",
      分析变量: "中药饮片",
      检验水准: "0.05",
      分析方法:
        "应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异。应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异",
      结论: [],
      table: {
        header: [],
        data: [],
      },
    },
    医家: {
      分组变量: "不同证型",
      分析变量: "中药饮片",
      检验水准: "0.05",
      分析方法:
        "应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异。应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异应用R语言（版本号3.5.1）进行数据分析。采用多个独立样本的卡方检验方法，分析同一证型不同医家的用药差异；以P<=0.05为有统计学差异",
      结论: [],
      table: {
        header: [],
        data: [],
      },
    },
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "差异分析",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["证型"].table = res["差异1"]["差异分析"].table;
        this.data["证型"]["结论"] = res["差异1"]["差异分析"]["结论"];
        this.data["医家"].table = res["差异2"]["差异分析"].table;
        this.data["医家"]["结论"] = res["差异2"]["差异分析"]["结论"];
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
  }
}
