















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { GetAnalysis, GetAnalysisExport } from "@/request/yian";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private searchData: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private filter: any = {
    支持度1: "30",
    置信度1: "50",
    支持度2: "30",
    置信度2: "50",
  };
  private data: any = {
    症证分析: {
      echarts_data: {
        categories: [],
        links: [],
        nodes: [],
      },
      全部数据: [],
      详情数据: [],
    },
    证药分析: {
      echarts_data: {
        categories: [],
        links: [],
        nodes: [],
      },
      全部数据: [],
      详情数据: [],
    },
    证症药分析: {
      echarts_data: {
        categories: [],
        links: [],
        nodes: [],
      },
      全部数据: [],
      详情数据: [],
    },
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private exportData(type: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetAnalysisExport(this, this.data[type]["全部数据"], loading)
      .then((res: any) => {
        loading.close();
        window.open(res.url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private drawEcharts1() {
    if (this.data["症证分析"].echarts_data.nodes.length === 0) {
      return;
    }
    const echartsChart: any = echarts.init(
      this.$refs.echarts1 as HTMLCanvasElement
    );
    const echartsOption: any = {
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "sankey",
          right: "15%",
          data: this.data["症证分析"].echarts_data.nodes,
          links: this.data["症证分析"].echarts_data.links,
          label: {
            normal: {
              color: "rgba(9, 27, 61, 0.6)",
              fontSize: 14,
              fontWeight: "400",
            },
          },
          itemStyle: {
            normal: {
              borderWidth: 1,
              borderColor: "#aaa",
            },
          },
          lineStyle: {
            normal: {
              color: "#666",
              borderColor: "black",
              borderWidth: 0,
              opacity: 0.3,
              curveness: 0.6,
            },
          },
        },
      ],
    };
    echartsChart.setOption(echartsOption, true);
  }
  private drawEcharts2() {
    if (this.data["证药分析"].echarts_data.nodes.length === 0) {
      return;
    }
    const echartsChart: any = echarts.init(
      this.$refs.echarts2 as HTMLCanvasElement
    );
    const echartsOption: any = {
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "sankey",
          right: "15%",
          data: this.data["证药分析"].echarts_data.nodes,
          links: this.data["证药分析"].echarts_data.links,
          nodeAlign: "left",
          label: {
            normal: {
              color: "rgba(9, 27, 61, 0.6)",
              fontSize: 14,
              fontWeight: "400",
            },
          },
          itemStyle: {
            normal: {
              borderWidth: 1,
              borderColor: "#aaa",
            },
          },
          lineStyle: {
            normal: {
              color: "#666",
              borderColor: "black",
              borderWidth: 0,
              opacity: 0.3,
              curveness: 0.6,
            },
          },
        },
      ],
    };
    echartsChart.setOption(echartsOption, true);
  }
  private drawEcharts3() {
    if (this.data["证症药分析"].echarts_data.nodes.length === 0) {
      return;
    }
    const echartsChart: any = echarts.init(
      this.$refs.echarts3 as HTMLCanvasElement
    );
    const echartsOption: any = {
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "sankey",
          right: "15%",
          data: this.data["证症药分析"].echarts_data.nodes,
          links: this.data["证症药分析"].echarts_data.links,
          label: {
            normal: {
              color: "rgba(9, 27, 61, 0.6)",
              fontSize: 14,
              fontWeight: "400",
            },
          },
          itemStyle: {
            normal: {
              borderWidth: 1,
              borderColor: "#aaa",
            },
          },
          lineStyle: {
            normal: {
              color: "#666",
              borderColor: "black",
              borderWidth: 0,
              opacity: 0.3,
              curveness: 0.6,
            },
          },
        },
      ],
    };
    echartsChart.setOption(echartsOption, true);
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "多维分析",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度1: this.filter["支持度1"] / 100,
      置信度1: this.filter["置信度1"] / 100,
      支持度2: this.filter["支持度2"] / 100,
      置信度2: this.filter["置信度2"] / 100,
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        if (!res["症证分析"].echarts_data.nodes) {
          res["症证分析"].echarts_data = {
            categories: [],
            links: [],
            nodes: [],
          };
        } else {
          res["症证分析"].echarts_data.links.forEach((ele: any) => {
            ele.value = ele.size;
          });
        }
        if (!res["证药分析"].echarts_data.nodes) {
          res["证药分析"].echarts_data = {
            categories: [],
            links: [],
            nodes: [],
          };
        } else {
          res["证药分析"].echarts_data.links.forEach((ele: any) => {
            ele.value = ele.size;
          });
        }
        if (!res["证症药分析"].echarts_data.nodes) {
          res["证症药分析"].echarts_data = {
            categories: [],
            links: [],
            nodes: [],
          };
        } else {
          res["证症药分析"].echarts_data.links.forEach((ele: any) => {
            ele.value = ele.size;
          });
        }
        this.data["症证分析"] = res["症证分析"];
        this.data["证药分析"] = res["证药分析"];
        this.data["证症药分析"] = res["证症药分析"];
        // 清除所有
        setTimeout(() => {
          this.drawEcharts1();
          this.drawEcharts2();
          this.drawEcharts3();
        }, 1000);
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {}
}
