















































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import wordcloud from "vue-wordcloud";
import { GetAnalysis, GetAnalysisExport } from "@/request/yian";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    wordcloud,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private searchData: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private loading1: any = false;
  private loading2: any = false;
  private loading3: any = false;
  private loading4: any = false;
  private loading5: any = false;
  private filter: any = {
    组合: {
      支持度: "20",
      置信度: "50",
    },
    关联规则: {
      支持度: "50",
      置信度: "50",
    },
    聚类: {
      聚类个数: 3,
    },
  };
  private data: any = {
    药味: {
      echarts_data: [],
      fre_result: [],
      全部数据: [],
      详细数据: [],
    },
    药性: {
      echarts_data: [],
      fre_result: [],
      全部数据: [],
      详细数据: [],
    },
    归经: {
      echarts_data: [],
      fre_result: [],
      全部数据: [],
      详细数据: [],
    },
    分布: {
      echarts: {
        name: ["补虚类", "清热类", "化痰类", "止血类", "安神类", "活血化瘀类"],
        data: [26, 19, 18, 16, 15, 9],
      },
      table: {
        count: 4,
        data: [
          ["乏力", "35", "16.59%"],
          ["失聪", "35", "16.59%"],
          ["言语不利", "35", "16.59%"],
          ["眠欠宁", "35", "16.59%"],
        ],
        name: ["功效", "出现频次", "百分比"],
      },
    },
    频次: {
      fre_result: [],
      max: "",
      全部数据: [],
      词云: [],
      详细数据: [],
    },
    组合: {
      echarts_data: {
        data: [],
        names: [],
      },
      全部数据: [],
      详细数据: [],
    },
    关联规则: {
      asso_result: [],
      全部数据: [],
      关联规则图: {
        categories: [],
        links: [],
        nodes: [],
      },
      详情数据: [],
    },
    聚类: {
      全部数据: [],
      聚类分析: {},
      聚类明细: {
        count: "",
        data: [],
      },
    },
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private exportData(type: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetAnalysisExport(this, this.data[type]["全部数据"], loading)
      .then((res: any) => {
        loading.close();
        window.open(res.url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private drawGuize() {
    if (this.$refs.guize) {
      const guizeChart: any = echarts.init(
        this.$refs.guize as HTMLCanvasElement
      );
      const guizeOption: any = {
        // color: [
        //   "#5470c6",
        //   "#91cc75",
        //   "#fac858",
        //   "#ee6666",
        //   "#73c0de",
        //   "#3ba272",
        //   "#fc8452",
        //   "#9a60b4",
        //   "#ea7ccc",
        // ],
        tooltip: {},
        // legend: [
        //   {
        //     data: graph.categories.map(function (a) {
        //       return a.name;
        //     })
        //   }
        // ],
        series: [
          {
            name: "",
            type: "graph",
            layout: "circular",
            circular: {
              rotateLabel: true,
            },
            data: this.data["关联规则"]["关联规则图"].nodes,
            links: this.data["关联规则"]["关联规则图"].links,
            categories: this.data["关联规则"]["关联规则图"].categories,
            roam: true,
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            labelLayout: {
              hideOverlap: true,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
          },
        ],
      };
      guizeChart.setOption(guizeOption, true);
    }
  }
  private drawZuhe() {
    let data: any = [];
    this.data["组合"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.zuhe) {
      const zuheChart: any = echarts.init(this.$refs.zuhe as HTMLCanvasElement);
      const zuheOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: 20,
          top: 10,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.data["组合"].echarts_data.names,
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "right",
            },
            data: data,
          },
        ],
      };
      zuheChart.setOption(zuheOption, true);
    }
  }
  private drawFenbu() {
    let data: any = [];
    this.data["分布"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.fenbu) {
      const fenbuChart: any = echarts.init(
        this.$refs.fenbu as HTMLCanvasElement
      );
      const fenbuOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.data["分布"].echarts_data.names,
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            data: data,
          },
        ],
      };
      fenbuChart.setOption(fenbuOption, true);
    }
  }
  private drawJulei() {
    const juleiChart: any = echarts.init(this.$refs.julei as HTMLCanvasElement);
    const juleiOption: any = {
      tooltip: {
        position: "top",
      },
      grid: {
        left: 120,
      },
      // legend: {},
      xAxis: {},
      yAxis: {},
      series: [],
    };
    this.data["聚类"]["聚类分析"]["分组"].forEach((ele: any) => {
      let arr: any = [];
      ele.data.forEach((e: any) => {
        arr.push(e.value);
      });
      juleiOption.series.push({
        name: ele.name,
        type: "scatter",
        data: arr,
      });
    });

    juleiChart.setOption(juleiOption, true);
  }
  private drawYaoxing() {
    const yaoxingChart: any = echarts.init(
      this.$refs.yaoxing as HTMLCanvasElement
    );
    let keys: any = [];
    let data: any = [];
    let max: any = 0;
    this.data["药性"].echarts_data.forEach((ele: any) => {
      data.push(ele.value);
      if (ele.value > max) {
        max = ele.value;
      }
    });
    this.data["药性"].echarts_data.forEach((ele: any) => {
      const obj = ele;
      obj.max = max;
      keys.push(ele);
    });
    const yaoxingOption: any = {
      radar: {
        indicator: keys,
        name: {
          color: "#333",
          fontSize: 16,
          //   lineHeight: 30,
        },
      },
      series: [
        {
          type: "radar",
          symbol: "circle",
          symbolSize: 6,
          label: {
            show: true,
            color: "#666",
            fontSize: 12,
          },
          lineStyle: {
            color: "#DC3831",
            width: 2,
          },
          itemStyle: {
            color: "#DC3831",
          },
          // areaStyle: {
          //   color: "rgba(84,112,198,1)",
          // },
          data: [
            {
              value: data,
              //   label: {
              //     show: true,
              //     formatter: (params: any) => {
              //       return params.value + "分";
              //     },
              //   },
            },
          ],
        },
      ],
    };
    yaoxingChart.setOption(yaoxingOption, true);
  }
  private drawYaowei() {
    const yaoxingChart: any = echarts.init(
      this.$refs.yaowei as HTMLCanvasElement
    );
    let keys: any = [];
    let data: any = [];
    let max: any = 0;
    this.data["药味"].echarts_data.forEach((ele: any) => {
      data.push(ele.value);
      if (ele.value > max) {
        max = ele.value;
      }
    });
    this.data["药味"].echarts_data.forEach((ele: any) => {
      const obj = ele;
      obj.max = max;
      keys.push(ele);
    });
    const yaoweiOption: any = {
      radar: {
        indicator: keys,
        name: {
          color: "#333",
          fontSize: 16,
          //   lineHeight: 30,
        },
      },
      series: [
        {
          type: "radar",
          symbol: "circle",
          symbolSize: 6,
          label: {
            show: true,
            color: "#666",
            fontSize: 12,
          },
          lineStyle: {
            color: "#DC3831",
            width: 2,
          },
          itemStyle: {
            color: "#DC3831",
          },
          // areaStyle: {
          //   color: "rgba(84,112,198,1)",
          // },
          data: [
            {
              value: data,
              //   label: {
              //     show: true,
              //     formatter: (params: any) => {
              //       return params.value + "分";
              //     },
              //   },
            },
          ],
        },
      ],
    };
    yaoxingChart.setOption(yaoweiOption, true);
  }
  private drawGuijing() {
    const guijingChart: any = echarts.init(
      this.$refs.guijing as HTMLCanvasElement
    );
    let keys: any = [];
    let data: any = [];
    let max: any = 0;
    this.data["归经"].echarts_data.forEach((ele: any) => {
      data.push(ele.value);
      if (ele.value > max) {
        max = ele.value;
      }
    });
    this.data["归经"].echarts_data.forEach((ele: any) => {
      const obj = ele;
      obj.max = max;
      keys.push(ele);
    });
    const guijingOption: any = {
      radar: {
        indicator: keys,
        name: {
          color: "#333",
          fontSize: 16,
          //   lineHeight: 30,
        },
      },
      series: [
        {
          type: "radar",
          symbol: "circle",
          symbolSize: 6,
          label: {
            show: true,
            color: "#666",
            fontSize: 12,
          },
          lineStyle: {
            color: "#DC3831",
            width: 2,
          },
          itemStyle: {
            color: "#DC3831",
          },
          // areaStyle: {
          //   color: "rgba(84,112,198,1)",
          // },
          data: [
            {
              value: data,
              //   label: {
              //     show: true,
              //     formatter: (params: any) => {
              //       return params.value + "分";
              //     },
              //   },
            },
          ],
        },
      ],
    };
    guijingChart.setOption(guijingOption, true);
  }
  private getData() {
    this.getXingweiguijingData();
    // this.getFenbuData();
    this.getPingciData();
    this.getZuheData();
    this.getGuizeData();
    this.getJuleiData();
  }
  private getXingweiguijingData() {
    const params: any = {
      method: "用药分析",
      parts: "性味归经",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    this.loading1 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading1 = false;
        this.data["药味"] = res["药味"];
        this.data["药性"] = res["药性"];
        this.data["归经"] = res["归经"];
        setTimeout(() => {
          this.drawYaoxing();
          this.drawYaowei();
          this.drawGuijing();
        }, 300);
      })
      .catch(() => {
        this.loading1 = false;
      });
  }
  private getFenbuData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "用药分析",
      parts: "功效",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        // this.data["分布"] = res["证型频次"];
        // setTimeout(() => {
        //   this.drawFenbu();
        // }, 300);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getZuheData() {
    const params: any = {
      method: "用药分析",
      parts: "药物组合",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度1: this.filter["组合"]["支持度"] / 100,
      置信度1: this.filter["组合"]["置信度"] / 100,
    };
    this.loading3 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading3 = false;
        this.data["组合"] = res["用药组合"];
        setTimeout(() => {
          this.drawZuhe();
        }, 300);
      })
      .catch(() => {
        this.loading3 = false;
      });
  }
  private getPingciData() {
    const params: any = {
      method: "用药分析",
      parts: "药物频次",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    this.loading2 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading2 = false;
        this.data["频次"] = res["用药频次"];
      })
      .catch(() => {
        this.loading2 = false;
      });
  }
  private getGuizeData() {
    const params: any = {
      method: "用药分析",
      parts: "关联规则",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度2: this.filter["关联规则"]["支持度"] / 100,
      置信度2: this.filter["关联规则"]["置信度"] / 100,
    };
    this.loading4 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading4 = false;
        this.data["关联规则"] = res["关联规则"];
        setTimeout(() => {
          this.drawGuize();
        }, 300);
      })
      .catch(() => {
        this.loading4 = false;
      });
  }
  private getJuleiData() {
    const params: any = {
      method: "用药分析",
      parts: "药物聚类",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      聚类个数: this.filter["聚类"]["聚类个数"],
    };
    this.loading5 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading5 = false;
        this.data["聚类"] = res["用药聚类"];
        setTimeout(() => {
          this.drawJulei();
        }, 300);
      })
      .catch(() => {
        this.loading5 = false;
      });
  }
  private mounted() {}
}
