













































































import { Component, Vue, Watch } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import LeftNav from "../../components/left-nav.vue";
import NavBox from "../../components/nav-box.vue";
import TopSearch from "../../components/top-search.vue";
import Zhengzhuang from "./components/zhengzhuang.vue";
import Zhenduan from "./components/zhenduan.vue";
import Zhizezhifa from "./components/zhizezhifa.vue";
import Yongyao from "./components/yongyao.vue";
import Duowei from "./components/duowei.vue";
import Chayi from "./components/chayi.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    LeftNav,
    NavBox,
    TopSearch,
    Zhengzhuang,
    Zhenduan,
    Zhizezhifa,
    Yongyao,
    Duowei,
    Chayi,
  },
})
export default class Name extends Vue {
  @Watch("jibing", { immediate: true })
  private jibingChange() {
    if (this.jibing && this.$refs.topSearch) {
      (this.$refs.topSearch as any).reset();
    }
  }
  private refreshDataTime: any = "";
  private tabs: any = [
    "症状分析",
    "诊断分析",
    // "治则治法分析",
    "用药分析",
    "多维分析",
    "差异分析",
  ];
  private cTab: any = "症状分析";
  private searchData: any = {
    症状: [],
    中医证型: [],
    中医治法: [],
    中医方剂: [],
    中药饮片: [],
    医家: [],
    源文献: [],
    年代: [],
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private filterChange(e: any) {
    this.searchData = e;
  }
  private goSearch(e: any) {
    this.refreshDataTime = new Date().getTime();
  }
  private changeTab(val: any) {
    this.cTab = val;
  }
  private mounted() {
    this.refreshDataTime = new Date().getTime();
  }
}
