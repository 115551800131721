
























































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import Empty from "@/components/empty.vue";
import { GetAnalysis, GetAnalysisExport } from "@/request/yian";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private searchData: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private filter: any = {
    组合: {
      支持度: "3",
      置信度: "90",
    },
    关联规则: {
      支持度: "3",
      置信度: "90",
    },
    聚类: {
      聚类个数: 3,
    },
  };
  private data: any = {
    分布: {
      echarts_data: {
        data: [],
        names: [],
      },
      fre_result: [],
      max: "",
      全部数据: [],
      详细数据: [],
    },
    组合: {
      echarts_data: {
        data: [],
        names: [],
      },
      全部数据: [],
      详细数据: [],
    },
    关联规则: {
      asso_result: [],
      全部数据: [],
      关联规则图: {
        categories: [],
        links: [],
        nodes: [],
      },
      详情数据: [],
    },
    聚类: {
      全部数据: [],
      聚类分析: {},
      聚类明细: {
        count: "",
        data: [],
      },
    },
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private exportData(type: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetAnalysisExport(this, this.data[type]["全部数据"], loading)
      .then((res: any) => {
        loading.close();
        window.open(res.url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getData() {
    this.getFenbuData();
    this.getZuheData();
    this.getGuizeData();
    this.getJuleiData();
  }
  private getFenbuData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "中医治法分析",
      parts: "治法分布",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["分布"] = res["治法频次"];
        setTimeout(() => {
          this.drawFenbu();
        }, 300);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getZuheData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "中医治法分析",
      parts: "治法组合",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度1: this.filter["组合"]["支持度"] / 100,
      置信度1: this.filter["组合"]["置信度"] / 100,
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["组合"] = res["治法组合"];
        setTimeout(() => {
          this.drawZuhe();
        }, 300);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getGuizeData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "中医治法分析",
      parts: "关联规则",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度2: this.filter["关联规则"]["支持度"] / 100,
      置信度2: this.filter["关联规则"]["置信度"] / 100,
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["关联规则"] = res["关联规则"];
        setTimeout(() => {
          this.drawGuize();
        }, 300);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getJuleiData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "中医治法分析",
      parts: "治法聚类",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      聚类个数: this.filter["聚类"]["聚类个数"],
    };
    GetAnalysis(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data["聚类"] = res["治法聚类"];
        setTimeout(() => {
          this.drawJulei();
        }, 300);
      })
      .catch(() => {
        loading.close();
      });
  }
  private drawGuize() {
    if (this.$refs.guize) {
      const guizeChart: any = echarts.init(
        this.$refs.guize as HTMLCanvasElement
      );
      const guizeOption: any = {
        // color: [
        //   "#5470c6",
        //   "#91cc75",
        //   "#fac858",
        //   "#ee6666",
        //   "#73c0de",
        //   "#3ba272",
        //   "#fc8452",
        //   "#9a60b4",
        //   "#ea7ccc",
        // ],
        tooltip: {},
        // legend: [
        //   {
        //     data: graph.categories.map(function (a) {
        //       return a.name;
        //     })
        //   }
        // ],
        series: [
          {
            name: "",
            type: "graph",
            layout: "circular",
            circular: {
              rotateLabel: true,
            },
            data: this.data["关联规则"]["关联规则图"].nodes,
            links: this.data["关联规则"]["关联规则图"].links,
            categories: this.data["关联规则"]["关联规则图"].categories,
            roam: true,
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            labelLayout: {
              hideOverlap: true,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
          },
        ],
      };
      guizeChart.setOption(guizeOption, true);
    }
  }
  private drawZuhe() {
    let data: any = [];
    this.data["组合"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.zuhe) {
      const zuheChart: any = echarts.init(this.$refs.zuhe as HTMLCanvasElement);
      const zuheOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: 20,
          top: 10,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.data["组合"].echarts_data.names,
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "right",
            },
            data: data,
          },
        ],
      };
      zuheChart.setOption(zuheOption, true);
    }
  }
  private drawFenbu() {
    let data: any = [];
    this.data["分布"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.fenbu) {
      const fenbuChart: any = echarts.init(
        this.$refs.fenbu as HTMLCanvasElement
      );
      const fenbuOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.data["分布"].echarts_data.names,
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            data: data,
          },
        ],
      };
      fenbuChart.setOption(fenbuOption, true);
    }
  }
  private drawJulei() {
    const juleiChart: any = echarts.init(this.$refs.julei as HTMLCanvasElement);
    const juleiOption: any = {
      tooltip: {
        position: "top",
      },
      grid: {
        left: 120,
      },
      // legend: {},
      xAxis: {},
      yAxis: {},
      series: [],
    };
    this.data["聚类"]["聚类分析"]["分组"].forEach((ele: any) => {
      let arr: any = [];
      ele.data.forEach((e: any) => {
        arr.push(e.value);
      });
      juleiOption.series.push({
        name: ele.name,
        type: "scatter",
        data: arr,
      });
    });

    juleiChart.setOption(juleiOption, true);
  }
  private mounted() {}
}
