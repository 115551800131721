


























































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { GetAnalysis, GetAnalysisExport } from "@/request/yian";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private searchData: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private loading1: any = false;
  private loading2: any = false;
  private loading3: any = false;
  private loading4: any = false;
  private filter: any = {
    组合: {
      支持度: "10",
      置信度: "50",
    },
    关联规则: {
      支持度: "10",
      置信度: "50",
    },
    聚类: {
      聚类个数: 3,
    },
  };
  private data: any = {
    分布: {
      echarts_data: {
        data: [],
        names: [],
      },
      fre_result: [],
      max: "",
      全部数据: [],
      详细数据: [],
    },
    组合: {
      echarts_data: {
        data: [],
        names: [],
      },
      全部数据: [],
      详细数据: [],
    },
    关联规则: {
      asso_result: [],
      全部数据: [],
      关联规则图: {
        categories: [],
        links: [],
        nodes: [],
      },
      详情数据: [],
    },
    聚类: {
      全部数据: [],
      聚类分析: {},
      聚类明细: {
        count: "",
        data: [],
      },
    },
  };
  private get jibing() {
    return this.$store.state.jibing;
  }
  private exportData(type: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetAnalysisExport(this, this.data[type]["全部数据"], loading)
      .then((res: any) => {
        loading.close();
        window.open(res.url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getData() {
    this.getFenbuData();
    this.getZuheData();
    this.getGuizeData();
    this.getJuleiData();
    return;
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      method: "诊断分析",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      // 支持度1: this.filter["症状组合"]["支持度"] / 100,
      // 置信度1: this.filter["症状组合"]["置信度"] / 100,
      // 支持度2: this.filter["关联规则"]["支持度"] / 100,
      // 置信度2: this.filter["关联规则"]["置信度"] / 100,
      // 聚类个数: this.filter["症状聚类"]["聚类个数"],
    };
    GetAnalysis(this, params, loading).then((res: any) => {});
    return;
    this.data = {
      分布: {
        echarts: [
          {
            name: "Cat",
            value: 26,
          },
          {
            name: "fish",
            value: 19,
          },
          {
            name: "things",
            value: 18,
          },
          {
            name: "look",
            value: 16,
          },
          {
            name: "two",
            value: 15,
          },
          {
            name: "fun",
            value: 9,
          },
        ],
        table: {
          count: 4,
          data: [
            ["乏力", "35", "16.59%"],
            ["失聪", "35", "16.59%"],
            ["言语不利", "35", "16.59%"],
            ["眠欠宁", "35", "16.59%"],
          ],
          name: ["症状", "出现频次", "百分比"],
        },
      },
      组合: {
        echarts: {
          name: [
            "气血不足",
            "气血亏虚",
            "阴虚火旺",
            "气血两虚",
            "气血亏虚",
            "肝肾阳虚",
          ],
          data: [18203, 23489, 29034, 104970, 131744, 630230],
        },
        table: {
          count: 8,
          data: [
            ["紫舌，胸闷，心痛", "35", "90"],
            ["胸闷，夜尿2-3次", "35", "90"],
            ["紫舌，口苦", "35", "90"],
            ["紫舌，口苦", "35", "90"],
            ["紫舌，口苦", "35", "90"],
            ["紫舌，口苦", "35", "90"],
            ["紫舌，口苦", "35", "90"],
            ["紫舌，口苦", "35", "90"],
          ],
          name: ["药物组合", "出现频次", "支持度（%）"],
        },
      },
      规则: {
        echarts: {
          nodes: [
            {
              id: "0",
              name: "山药",
              symbolSize: 19.12381,
              x: -266.82776,
              y: 299.6904,
              value: 28.685715,
              category: 0,
            },
            {
              id: "1",
              name: "茯苓",
              symbolSize: 2.6666666666666665,
              x: -418.08344,
              y: 446.8853,
              value: 4,
              category: 0,
            },
            {
              id: "2",
              name: "山萸肉",
              symbolSize: 6.323809333333333,
              x: -212.76357,
              y: 245.29176,
              value: 9.485714,
              category: 1,
            },
            {
              id: "3",
              name: "盐桑螵蛸",
              symbolSize: 6.323809333333333,
              x: -242.82404,
              y: 235.26283,
              value: 9.485714,
              category: 1,
            },
            {
              id: "4",
              name: "全蝎",
              symbolSize: 2.6666666666666665,
              x: -379.30386,
              y: 429.06424,
              value: 4,
              category: 0,
            },
            {
              id: "5",
              name: "熟地黄",
              symbolSize: 2.6666666666666665,
              x: -417.26337,
              y: 406.03506,
              value: 4,
              category: 0,
            },
            {
              id: "6",
              name: "黄芪",
              symbolSize: 2.6666666666666665,
              x: -332.6012,
              y: 485.16974,
              value: 4,
              category: 0,
            },
            {
              id: "7",
              name: "盐泽泻",
              symbolSize: 2.6666666666666665,
              x: -382.69568,
              y: 475.09113,
              value: 4,
              category: 0,
            },
            {
              id: "8",
              name: "牡丹皮",
              symbolSize: 2.6666666666666665,
              x: -320.384,
              y: 387.17325,
              value: 4,
              category: 0,
            },
            {
              id: "9",
              name: "太子参",
              symbolSize: 2.6666666666666665,
              x: -344.39832,
              y: 451.16772,
              value: 4,
              category: 0,
            },
            {
              id: "10",
              name: "燀桃仁",
              symbolSize: 2.6666666666666665,
              x: -89.34107,
              y: 234.56128,
              value: 4,
              category: 1,
            },
            {
              id: "11",
              name: "赤芍",
              symbolSize: 66.66666666666667,
              x: -87.93029,
              y: -6.8120565,
              value: 100,
              category: 1,
            },
            {
              id: "12",
              name: "当归",
              symbolSize: 4.495239333333333,
              x: -339.77908,
              y: -184.69139,
              value: 6.742859,
              category: 1,
            },
            {
              id: "13",
              name: "红花",
              symbolSize: 2.6666666666666665,
              x: -194.31313,
              y: 178.55301,
              value: 4,
              category: 1,
            },
            {
              id: "14",
              name: "地龙",
              symbolSize: 2.6666666666666665,
              x: -158.05168,
              y: 201.99768,
              value: 4,
              category: 1,
            },
            {
              id: "15",
              name: "酒川芎",
              symbolSize: 2.6666666666666665,
              x: -127.701546,
              y: 242.55057,
              value: 4,
              category: 1,
            },
            {
              id: "16",
              name: "烫水蛭",
              symbolSize: 17.295237333333333,
              x: -385.2226,
              y: -393.5572,
              value: 25.942856,
              category: 2,
            },
            {
              id: "17",
              name: "盐杜仲",
              symbolSize: 13.638097333333334,
              x: -516.55884,
              y: -393.98975,
              value: 20.457146,
              category: 2,
            },
            {
              id: "18",
              name: "炒金樱子肉",
              symbolSize: 13.638097333333334,
              x: -464.79382,
              y: -493.57944,
              value: 20.457146,
              category: 2,
            },
            {
              id: "19",
              name: "炒白扁豆",
              symbolSize: 13.638097333333334,
              x: -515.1624,
              y: -456.9891,
              value: 20.457146,
              category: 2,
            },
            {
              id: "20",
              name: "薏苡仁",
              symbolSize: 13.638097333333334,
              x: -408.12122,
              y: -464.5048,
              value: 20.457146,
              category: 2,
            },
            {
              id: "21",
              name: "白术",
              symbolSize: 13.638097333333334,
              x: -456.44113,
              y: -425.13303,
              value: 20.457146,
              category: 2,
            },
            {
              id: "22",
              name: "陈皮",
              symbolSize: 13.638097333333334,
              x: -459.1107,
              y: -362.5133,
              value: 20.457146,
              category: 2,
            },
            {
              id: "23",
              name: "莲子",
              symbolSize: 28.266666666666666,
              x: -313.42786,
              y: -289.44803,
              value: 42.4,
              category: 2,
            },
            {
              id: "24",
              name: "砂仁",
              symbolSize: 20.95238266666667,
              x: 4.6313396,
              y: -273.8517,
              value: 31.428574,
              category: 7,
            },
            {
              id: "25",
              name: "党参段",
              symbolSize: 30.095235333333335,
              x: 82.80825,
              y: -203.1144,
              value: 45.142853,
              category: 7,
            },
            {
              id: "26",
              name: "山慈菇",
              symbolSize: 20.95238266666667,
              x: 78.64646,
              y: -31.512747,
              value: 31.428574,
              category: 6,
            },
          ],
          links: [
            {
              source: "1",
              target: "0",
            },
            {
              source: "2",
              target: "0",
            },
            {
              source: "3",
              target: "0",
            },
            {
              source: "3",
              target: "2",
            },
            {
              source: "4",
              target: "0",
            },
            {
              source: "5",
              target: "0",
            },
            {
              source: "6",
              target: "0",
            },
            {
              source: "7",
              target: "0",
            },
            {
              source: "8",
              target: "0",
            },
            {
              source: "9",
              target: "0",
            },
            {
              source: "11",
              target: "0",
            },
            {
              source: "11",
              target: "2",
            },
            {
              source: "11",
              target: "3",
            },
            {
              source: "11",
              target: "10",
            },
            {
              source: "12",
              target: "11",
            },
            {
              source: "13",
              target: "11",
            },
            {
              source: "14",
              target: "11",
            },
            {
              source: "15",
              target: "11",
            },
            {
              source: "17",
              target: "16",
            },
            {
              source: "18",
              target: "16",
            },
            {
              source: "18",
              target: "17",
            },
            {
              source: "19",
              target: "16",
            },
            {
              source: "19",
              target: "17",
            },
            {
              source: "19",
              target: "18",
            },
            {
              source: "20",
              target: "16",
            },
            {
              source: "20",
              target: "17",
            },
            {
              source: "20",
              target: "18",
            },
            {
              source: "20",
              target: "19",
            },
            {
              source: "21",
              target: "16",
            },
            {
              source: "21",
              target: "17",
            },
            {
              source: "21",
              target: "18",
            },
            {
              source: "21",
              target: "19",
            },
            {
              source: "21",
              target: "20",
            },
            {
              source: "22",
              target: "16",
            },
            {
              source: "22",
              target: "17",
            },
            {
              source: "22",
              target: "18",
            },
            {
              source: "22",
              target: "19",
            },
            {
              source: "22",
              target: "20",
            },
            {
              source: "22",
              target: "21",
            },
            {
              source: "23",
              target: "11",
            },
            {
              source: "23",
              target: "12",
            },
            {
              source: "23",
              target: "16",
            },
            {
              source: "23",
              target: "17",
            },
            {
              source: "23",
              target: "18",
            },
            {
              source: "23",
              target: "19",
            },
            {
              source: "23",
              target: "20",
            },
            {
              source: "23",
              target: "21",
            },
            {
              source: "23",
              target: "22",
            },
            {
              source: "24",
              target: "11",
            },
            {
              source: "24",
              target: "23",
            },
            {
              source: "25",
              target: "11",
            },
            {
              source: "25",
              target: "23",
            },
            {
              source: "25",
              target: "24",
            },
            {
              source: "26",
              target: "11",
            },
            {
              source: "26",
              target: "16",
            },
            {
              source: "26",
              target: "24",
            },
            {
              source: "26",
              target: "25",
            },
            {
              source: "27",
              target: "11",
            },
            {
              source: "27",
              target: "23",
            },
            {
              source: "27",
              target: "24",
            },
            {
              source: "27",
              target: "25",
            },
            {
              source: "27",
              target: "26",
            },
            {
              source: "28",
              target: "11",
            },
            {
              source: "28",
              target: "27",
            },
            {
              source: "29",
              target: "11",
            },
            {
              source: "29",
              target: "23",
            },
            {
              source: "29",
              target: "27",
            },
            {
              source: "30",
              target: "23",
            },
            {
              source: "31",
              target: "11",
            },
            {
              source: "31",
              target: "23",
            },
            {
              source: "31",
              target: "27",
            },
            {
              source: "31",
              target: "30",
            },
            {
              source: "32",
              target: "11",
            },
            {
              source: "33",
              target: "11",
            },
            {
              source: "33",
              target: "27",
            },
            {
              source: "34",
              target: "11",
            },
            {
              source: "34",
              target: "29",
            },
            {
              source: "35",
              target: "11",
            },
            {
              source: "35",
              target: "29",
            },
            {
              source: "35",
              target: "34",
            },
            {
              source: "36",
              target: "11",
            },
            {
              source: "36",
              target: "29",
            },
            {
              source: "36",
              target: "34",
            },
            {
              source: "36",
              target: "35",
            },
            {
              source: "37",
              target: "11",
            },
            {
              source: "37",
              target: "29",
            },
            {
              source: "37",
              target: "34",
            },
            {
              source: "37",
              target: "35",
            },
            {
              source: "37",
              target: "36",
            },
            {
              source: "38",
              target: "11",
            },
            {
              source: "38",
              target: "29",
            },
            {
              source: "38",
              target: "34",
            },
            {
              source: "38",
              target: "35",
            },
            {
              source: "38",
              target: "36",
            },
            {
              source: "38",
              target: "37",
            },
            {
              source: "39",
              target: "25",
            },
            {
              source: "40",
              target: "25",
            },
            {
              source: "41",
              target: "24",
            },
            {
              source: "41",
              target: "25",
            },
            {
              source: "42",
              target: "24",
            },
            {
              source: "42",
              target: "25",
            },
            {
              source: "42",
              target: "41",
            },
            {
              source: "43",
              target: "11",
            },
            {
              source: "43",
              target: "26",
            },
            {
              source: "43",
              target: "27",
            },
            {
              source: "44",
              target: "11",
            },
            {
              source: "44",
              target: "28",
            },
            {
              source: "45",
              target: "28",
            },
            {
              source: "47",
              target: "46",
            },
            {
              source: "48",
              target: "11",
            },
            {
              source: "48",
              target: "25",
            },
            {
              source: "48",
              target: "27",
            },
            {
              source: "48",
              target: "47",
            },
            {
              source: "49",
              target: "11",
            },
            {
              source: "49",
              target: "26",
            },
            {
              source: "50",
              target: "24",
            },
            {
              source: "50",
              target: "49",
            },
            {
              source: "51",
              target: "11",
            },
            {
              source: "51",
              target: "26",
            },
            {
              source: "51",
              target: "49",
            },
            {
              source: "52",
              target: "39",
            },
            {
              source: "52",
              target: "51",
            },
            {
              source: "53",
              target: "51",
            },
            {
              source: "54",
              target: "26",
            },
            {
              source: "54",
              target: "49",
            },
            {
              source: "54",
              target: "51",
            },
            {
              source: "55",
              target: "11",
            },
            {
              source: "55",
              target: "16",
            },
            {
              source: "55",
              target: "25",
            },
            {
              source: "55",
              target: "26",
            },
            {
              source: "55",
              target: "39",
            },
            {
              source: "55",
              target: "41",
            },
            {
              source: "55",
              target: "48",
            },
            {
              source: "55",
              target: "49",
            },
            {
              source: "55",
              target: "51",
            },
            {
              source: "55",
              target: "54",
            },
            {
              source: "56",
              target: "49",
            },
            {
              source: "56",
              target: "55",
            },
            {
              source: "57",
              target: "41",
            },
            {
              source: "57",
              target: "48",
            },
            {
              source: "57",
              target: "55",
            },
            {
              source: "58",
              target: "11",
            },
            {
              source: "58",
              target: "27",
            },
            {
              source: "58",
              target: "48",
            },
            {
              source: "58",
              target: "55",
            },
            {
              source: "58",
              target: "57",
            },
            {
              source: "59",
              target: "48",
            },
            {
              source: "59",
              target: "55",
            },
            {
              source: "59",
              target: "57",
            },
            {
              source: "59",
              target: "58",
            },
            {
              source: "60",
              target: "48",
            },
            {
              source: "60",
              target: "58",
            },
            {
              source: "60",
              target: "59",
            },
            {
              source: "61",
              target: "48",
            },
            {
              source: "61",
              target: "55",
            },
            {
              source: "61",
              target: "57",
            },
            {
              source: "61",
              target: "58",
            },
            {
              source: "61",
              target: "59",
            },
            {
              source: "61",
              target: "60",
            },
            {
              source: "62",
              target: "41",
            },
            {
              source: "62",
              target: "48",
            },
            {
              source: "62",
              target: "55",
            },
            {
              source: "62",
              target: "57",
            },
            {
              source: "62",
              target: "58",
            },
            {
              source: "62",
              target: "59",
            },
            {
              source: "62",
              target: "60",
            },
            {
              source: "62",
              target: "61",
            },
            {
              source: "63",
              target: "48",
            },
            {
              source: "63",
              target: "55",
            },
            {
              source: "63",
              target: "57",
            },
            {
              source: "63",
              target: "58",
            },
            {
              source: "63",
              target: "59",
            },
            {
              source: "63",
              target: "60",
            },
            {
              source: "63",
              target: "61",
            },
            {
              source: "63",
              target: "62",
            },
            {
              source: "64",
              target: "11",
            },
            {
              source: "64",
              target: "48",
            },
            {
              source: "64",
              target: "55",
            },
            {
              source: "64",
              target: "57",
            },
            {
              source: "64",
              target: "58",
            },
            {
              source: "64",
              target: "59",
            },
            {
              source: "64",
              target: "60",
            },
            {
              source: "64",
              target: "61",
            },
            {
              source: "64",
              target: "62",
            },
            {
              source: "64",
              target: "63",
            },
            {
              source: "65",
              target: "48",
            },
            {
              source: "65",
              target: "55",
            },
            {
              source: "65",
              target: "57",
            },
            {
              source: "65",
              target: "58",
            },
            {
              source: "65",
              target: "59",
            },
            {
              source: "65",
              target: "60",
            },
            {
              source: "65",
              target: "61",
            },
            {
              source: "65",
              target: "62",
            },
            {
              source: "65",
              target: "63",
            },
            {
              source: "65",
              target: "64",
            },
            {
              source: "66",
              target: "48",
            },
            {
              source: "66",
              target: "58",
            },
            {
              source: "66",
              target: "59",
            },
            {
              source: "66",
              target: "60",
            },
            {
              source: "66",
              target: "61",
            },
            {
              source: "66",
              target: "62",
            },
            {
              source: "66",
              target: "63",
            },
            {
              source: "66",
              target: "64",
            },
            {
              source: "66",
              target: "65",
            },
            {
              source: "67",
              target: "57",
            },
            {
              source: "68",
              target: "11",
            },
            {
              source: "68",
              target: "24",
            },
            {
              source: "68",
              target: "25",
            },
            {
              source: "68",
              target: "27",
            },
            {
              source: "68",
              target: "41",
            },
            {
              source: "68",
              target: "48",
            },
            {
              source: "69",
              target: "11",
            },
            {
              source: "69",
              target: "24",
            },
            {
              source: "69",
              target: "25",
            },
            {
              source: "69",
              target: "27",
            },
            {
              source: "69",
              target: "41",
            },
            {
              source: "69",
              target: "48",
            },
            {
              source: "69",
              target: "68",
            },
            {
              source: "70",
              target: "11",
            },
            {
              source: "70",
              target: "24",
            },
            {
              source: "70",
              target: "25",
            },
            {
              source: "70",
              target: "27",
            },
            {
              source: "70",
              target: "41",
            },
            {
              source: "70",
              target: "58",
            },
            {
              source: "70",
              target: "68",
            },
            {
              source: "70",
              target: "69",
            },
            {
              source: "71",
              target: "11",
            },
            {
              source: "71",
              target: "25",
            },
            {
              source: "71",
              target: "27",
            },
            {
              source: "71",
              target: "41",
            },
            {
              source: "71",
              target: "48",
            },
            {
              source: "71",
              target: "68",
            },
            {
              source: "71",
              target: "69",
            },
            {
              source: "71",
              target: "70",
            },
            {
              source: "72",
              target: "11",
            },
            {
              source: "72",
              target: "26",
            },
            {
              source: "72",
              target: "27",
            },
            {
              source: "73",
              target: "48",
            },
            {
              source: "74",
              target: "48",
            },
            {
              source: "74",
              target: "73",
            },
            {
              source: "75",
              target: "25",
            },
            {
              source: "75",
              target: "41",
            },
            {
              source: "75",
              target: "48",
            },
            {
              source: "75",
              target: "68",
            },
            {
              source: "75",
              target: "69",
            },
            {
              source: "75",
              target: "70",
            },
            {
              source: "75",
              target: "71",
            },
            {
              source: "76",
              target: "48",
            },
            {
              source: "76",
              target: "58",
            },
            {
              source: "76",
              target: "62",
            },
            {
              source: "76",
              target: "63",
            },
            {
              source: "76",
              target: "64",
            },
            {
              source: "76",
              target: "65",
            },
            {
              source: "76",
              target: "66",
            },
          ],
          categories: [
            {
              name: "A",
            },
            {
              name: "B",
            },
            {
              name: "C",
            },
            {
              name: "D",
            },
            {
              name: "E",
            },
            {
              name: "F",
            },
            {
              name: "G",
            },
            {
              name: "H",
            },
            {
              name: "I",
            },
          ],
        },
        table: {
          count: 9,
          data: [
            ["舌红，苔腻---发黄，暗黑", "24.17%", "46.00%", "1.90"],
            ["舌红---苔白", "24.17%", "46.00%", "1.90"],
            ["舌绛紫，苔腻，齿痕---齿痕", "24.17%", "46.00%", "1.90"],
            ["舌红---发黑", "24.17%", "46.00%", "1.90"],
            ["舌红---苔腻", "24.17%", "46.00%", "1.90"],
            ["舌淡---苔白", "24.17%", "46.00%", "1.90"],
            ["舌绛---齿痕", "24.17%", "46.00%", "1.90"],
            ["舌红---暗黄", "24.17%", "46.00%", "1.90"],
            ["舌绛紫---齿痕", "24.17%", "46.00%", "1.90"],
          ],
          name: ["X-Y关联规则", "支持度", "置信度", "提升度"],
        },
      },
      聚类: {
        echarts: {
          data: [
            [3.275154, 2.957587],
            [-3.344465, 2.603513],
            [0.355083, -3.376585],
            [1.852435, 3.547351],
            [-2.078973, 2.552013],
            [-0.993756, -0.884433],
            [2.682252, 4.007573],
            [-3.087776, 2.878713],
          ],
          pieces: [
            {
              value: 0,
              label: "cluster 0",
            },
            {
              value: 1,
              label: "cluster 1",
            },
            {
              value: 2,
              label: "cluster 2",
            },
          ],
        },

        table: {
          count: 8,
          data: [
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
            ["1", "紫舌，胸闷，心痛"],
          ],
          name: ["分组", "内容"],
        },
      },
    };
  }
  private getFenbuData() {
    const params: any = {
      method: "诊断分析",
      parts: "证型分布",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
    };
    this.loading1 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading1 = false;
        this.data["分布"] = res["证型频次"];
        setTimeout(() => {
          this.drawFenbu();
        }, 300);
      })
      .catch(() => {
        this.loading1 = false;
      });
  }
  private getZuheData() {
    const params: any = {
      method: "诊断分析",
      parts: "证型组合",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度1: this.filter["组合"]["支持度"] / 100,
      置信度1: this.filter["组合"]["置信度"] / 100,
    };
    this.loading2 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading2 = false;
        this.data["组合"] = res["证型组合"];
        setTimeout(() => {
          this.drawZuhe();
        }, 300);
      })
      .catch(() => {
        this.loading2 = false;
      });
  }
  private getGuizeData() {
    const params: any = {
      method: "诊断分析",
      parts: "关联规则",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      支持度2: this.filter["关联规则"]["支持度"] / 100,
      置信度2: this.filter["关联规则"]["置信度"] / 100,
    };
    this.loading3 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading3 = false;
        this.data["关联规则"] = res["关联规则"];
        setTimeout(() => {
          this.drawGuize();
        }, 300);
      })
      .catch(() => {
        this.loading3 = false;
      });
  }
  private getJuleiData() {
    const params: any = {
      method: "诊断分析",
      parts: "中医证型聚类",
      bingzhong_id: this.jibing.child.id,
      症状: this.searchData["症状"],
      中医证型: this.searchData["中医证型"],
      中医治法: this.searchData["中医治法"],
      中医方剂: this.searchData["中医方剂"],
      中药饮片: this.searchData["中药饮片"],
      医家: this.searchData["医家"],
      文献: this.searchData["源文献"],
      年代: this.searchData["年代"],
      聚类个数: this.filter["聚类"]["聚类个数"],
    };
    this.loading4 = true;
    GetAnalysis(this, params)
      .then((res: any) => {
        this.loading4 = false;
        this.data["聚类"] = res["证型聚类"];
        setTimeout(() => {
          this.drawJulei();
        }, 300);
      })
      .catch(() => {
        this.loading4 = false;
      });
  }
  private drawGuize() {
    if (this.$refs.guize) {
      const guizeChart: any = echarts.init(
        this.$refs.guize as HTMLCanvasElement
      );
      const guizeOption: any = {
        // color: [
        //   "#5470c6",
        //   "#91cc75",
        //   "#fac858",
        //   "#ee6666",
        //   "#73c0de",
        //   "#3ba272",
        //   "#fc8452",
        //   "#9a60b4",
        //   "#ea7ccc",
        // ],
        tooltip: {},
        // legend: [
        //   {
        //     data: graph.categories.map(function (a) {
        //       return a.name;
        //     })
        //   }
        // ],
        series: [
          {
            name: "",
            type: "graph",
            layout: "circular",
            circular: {
              rotateLabel: true,
            },
            data: this.data["关联规则"]["关联规则图"].nodes,
            links: this.data["关联规则"]["关联规则图"].links,
            categories: this.data["关联规则"]["关联规则图"].categories,
            roam: true,
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            labelLayout: {
              hideOverlap: true,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
          },
        ],
      };
      guizeChart.setOption(guizeOption, true);
    }
  }
  private drawZuhe() {
    let data: any = [];
    this.data["组合"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.zuhe) {
      const zuheChart: any = echarts.init(this.$refs.zuhe as HTMLCanvasElement);
      const zuheOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: 20,
          top: 10,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.data["组合"].echarts_data.names,
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "right",
            },
            data: data,
          },
        ],
      };
      zuheChart.setOption(zuheOption, true);
    }
  }
  private drawFenbu() {
    let data: any = [];
    this.data["分布"].echarts_data.data.forEach((ele: any) => {
      data.push(ele.value);
    });
    if (this.$refs.fenbu) {
      const fenbuChart: any = echarts.init(
        this.$refs.fenbu as HTMLCanvasElement
      );
      const fenbuOption: any = {
        color: ["#409eff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "15%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.data["分布"].echarts_data.names,
          // boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            data: data,
          },
        ],
      };
      fenbuChart.setOption(fenbuOption, true);
    }
  }
  private drawJulei() {
    const juleiChart: any = echarts.init(this.$refs.julei as HTMLCanvasElement);
    const juleiOption: any = {
      tooltip: {
        position: "top",
      },
      grid: {
        left: 120,
      },
      // legend: {},
      xAxis: {},
      yAxis: {},
      series: [],
    };
    this.data["聚类"]["聚类分析"]["分组"].forEach((ele: any) => {
      let arr: any = [];
      ele.data.forEach((e: any) => {
        arr.push(e.value);
      });
      juleiOption.series.push({
        name: ele.name,
        type: "scatter",
        data: arr,
      });
    });

    juleiChart.setOption(juleiOption, true);
  }
  private mounted() {}
}
